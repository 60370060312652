<template>
  <div class="home">
    <div class="container">
        <Top />
        <div class="main">
          <div class="main_banner">
            <van-swipe :autoplay="autoplayTime">
              <van-swipe-item v-for="(item, index) in bigBannerList" :key="index" @touchstart="onTouchStart">
                <a :href="item.url"><img v-if="item.play_type == 1" v-lazy="item.img" /></a>
                <video style="z-index: 1;" :id="index" class="videoStyle myVideo" :class="index" loop controls preload="metadata" type="video/mp4" :ref="'testVideo' + index" v-if="item.play_type == 2" :src='item.img' @play="onPlay" @pause="onPause" ></video>
              </van-swipe-item>
            </van-swipe>
          </div>
        </div>
    </div>
    <div class="main_small_banner">
        <div class="banner_title">
            <img src="../assets/image/recommend.png" alt="">
        </div>
        <div class="recommendPage" style="">
          <swiper :options="swiperOption" ref="mySwiper" v-if="samllBannerList.length">
              <swiper-slide v-for="(item, index) in samllBannerList" :key="index"><a :href="item.url"><img :src="item.img"></a></swiper-slide>
          </swiper>
        </div>
    </div>
    <div class="download">
        <div class="download_title">
          <img src="../assets/image/download.png" alt="">
        </div>
        <div class="download_main"  v-for="(item, index) in apkList" :key="index" >
          <div class="download_item  clearFix" :class="item.is_hot == 1 ? 'hot_active' : ''">
            <div class="item_left">
              <img :src="item.img" alt="">
            </div>
            <div class="item_middle" v-if="item.is_hot == 1">
              <div class="item_middle_title">{{item.title}}</div>
              <div class="item_middle_detail">{{item.detail}}</div>
              <a :href="item.url">
                <div class="item_middle_download cleanFix">
                  <div class="item_middle_download_icon cleanFix"><img :src="item.button_icon" alt=""></div>
                  <div class="item_middle_download_detail">{{ item.button_name }}</div>
                </div>
              </a>
            </div>
            <div class="item_middle" v-if="item.is_hot == 2">
              <div class="item_middle_name">{{item.title}}</div>
              <a :href="item.url">
                <div class="item_middle_download cleanFix">
                  <div class="item_middle_download_icon cleanFix"><img :src="item.button_icon" alt=""></div>
                  <div class="item_middle_download_detail">{{ item.button_name }}</div>
                </div>
              </a>
            </div>
            <div class="item_right">
              <div><img :src="item.code" alt=""></div>
              <div class="item_right_scanning">สแกนเพื่อดาวน์โหลด</div>
            </div>
            <div class="is_hot"  v-if="item.is_hot == 1">
              <img src="../assets/image/hot.png" alt="">
            </div>
          </div>
        </div>
    </div>

    <FooderText />
    <FooterNav />
  </div>
    
</template>

<script>
import FooderText from "../components/FooterText.vue";
import FooterNav from "../components/FooterNav.vue";
import Top from "../components/Top.vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

export default {
  components: {  
    swiper,
    swiperSlide,
    FooderText,
    FooterNav,
    Top
  },
  data() {
    return {
      active: 0,
      bigBannerType:1,
      samllBannerType:2,
      bigBannerList:[],
      samllBannerList:[],
      apkList:[],
      autoplayTime:3000,
      swiperOption: {
          effect: 'coverflow',
          loop: true,
          observer: true, 
          observeParents: true,
          watchSlidesProgress: true,
          centeredSlides: true,
          slidesPerView: 'auto',
          freeMode: true,
          spaceBetween: 20,
          slideToClickedSlide:true,
          mousewheel:true,
          autoplay: {
              delay: 3000,
              stopOnLastSlide: false,
              centeredSlides: true,
              disableOnInteraction: false
          },
          coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 0,
            modifier: 1,
            slideShadows: false,
          }
      },

    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  created() {
    this.getBigBanner()
    this.getSmallBanner()
    this.getApkList()
  },
  updated(){
    this.firstAutoPlay()
  },
  methods:{
    getBigBanner(){
        this.service.post('Home/getBannerList',{type: this.bigBannerType})
        .then((res) => {
            this.bigBannerList = res.data.data
        })
    },
    getSmallBanner(){
        this.service.post('Home/getBannerList',{type: this.samllBannerType})
        .then((res) => {
            this.samllBannerList = res.data.data
        }) 
    },
    getApkList(){
        this.service.post('Home/getApkList',{})
        .then((res) => {
            this.apkList = res.data.data
        }) 
    },
    onPlay() {
        this.autoplayTime = 0
    },
    onPause() {
        this.autoplayTime = 3000
    },
    onTouchStart(){
        this.autoplayTime = 3000
    },
    firstAutoPlay(){
        this.$nextTick(() => {
            eval(this.$refs.testVideo0)[0].muted = true;
            eval(this.$refs.testVideo0)[0].play();
        })
    },
  }
};
</script>

<style scoped lang="less">
    .van-swipe-item .videoStyle{
      width: 100%;
      height: 100%;
      border-radius: 19px;
      -webkit-border-radius: 19px;
      -ms-border-radius: 19px;
      overflow: hidden;
      object-fit: cover;
    }
    
    .main{
      width: 100%;
      .main_banner{
        margin-bottom: 30px;
        .swiper-container img{
          width: 100%;
        }
        .van-swipe{
          width: 690px;
          height: 420px;
          border-radius: 15px;
          .van-swipe-item img{
            width: 100%;
            height: 100%;
          }
        }
      }
    }

  .banner_title{
    height: 35px;
    color: #FFFFFF;
    font-size: 28px;
    text-align: center;
    margin-bottom: 20px;
    img{
      width: 270px;
      height: 50px;
    }
  }
  .main_small_banner{
    margin-bottom: 45px;
    width: 750px;
    overflow: hidden;
    .swiper-container{
      overflow: visible!important;;
      width: 750px;
      height: 310px;
      .swiper-wrapper .swiper-slide{
        width: 550px!important;
        border-radius: 15px;
      }
      .swiper-container .swiper-wrapper .swiper-slide img{
        width: 100%;
        height: 100%;
      }
      .swiper-slide img{
        width: 100%;
        height: 100%!important;
        border-radius: 15px;
      }
    }
  }
  .download{
    margin-bottom: 40px;
    .download_title{
      width: 690px;
      text-align: center;
    }
    padding: 0px 30px;
    .download_title{
      width: 690px;
      text-align: center;
      img{
        width: 270px;
        height: 50px;
      }
    }
    .download_main{
      width: 690px;
      margin-bottom: 20px;
      position: relative;
      .download_item{
        padding: 15px;
        background:linear-gradient(to bottom,#E3EEFF,#FFFFFF,#E3EEFF);
        border-radius: 10px;
        height: 160px;
        
        .item_left{
          width: 150px;
          height: 150px;
          margin-top: 5px;
          float: left;
          border-radius: 20px;
          margin-right: 5px;
          img{
            border-radius: 20px;
            width: 100%;
            height: 100%;
          }
        }
        .item_middle{
          width: 345px;
          height: 160px;
          float: left;
          .item_middle_title{
            height: 36px;
            font-size: 32px;
            line-height: 36px;
            color: #F44E4E;
            font-family: 'kt';
          }

          .item_middle_name{
            height: 70px;
            font-size: 32px;
            line-height: 90px;
            font-weight: bold;
            font-family: 'kt';
          }
          .item_middle_detail{
            font-size: 20px;
            line-height: 28px;
            height: 50px;
            color: #666666;
            // font-family: pingfang;
          }
          .item_middle_download{
            width: 250px;
            height: 55px;
            background:linear-gradient(to right,#357BFF,#37DCF3);
            border-radius: 10px;
            padding:0px 10px;
            margin-top: 20px;
            .item_middle_download_icon{
              margin-top: 10px;
              margin-right: 10px;
              float: left;
              width: 35px;
              height: 35px;
              img{
                width: 100%;
                height: 100%;
                display: block;
              }
            }
            .item_middle_download_detail{
              font-size: 22px;
              height: 55px;
              line-height: 55px;
              width: 205px;
              color: #FFFFFF;
              word-break: break-word;
              word-wrap:break-word;
              float: left;
            }
          }
        }
        .item_right{
          width: 160px;
          height: 140px;
          float: left;
          text-align: center;
          div{
            height: 140px;
          }
          img{
            width: 140px;
            height: 140px;
          }
          .item_right_scanning{
            font-size: 18px;
            color: #999999;
          }
        }
        .is_hot{
          position: absolute;
          height: 100px;
          width: 100px;
          top: 0px;
          left: 0px;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .download .download_main .hot_active{
    background:linear-gradient(to bottom,#FFF6AC,#E3EEFF)!important;
  }
  
</style>

